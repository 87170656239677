import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/one_bond_generation'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/index/index.vue'),
  },
  {
    path: '/one_bond_generation',
    name: 'one_bond_generation',
    component: () => import('@/views/one_bond_generation/index.vue'),
  },
  {
    path: '/detail_function',
    name: 'detail_function',
    component: () => import('@/views/detail_function/index.vue'),
  },
  {
    path: '/person',
    name: 'person',
    component: () => import('@/views/person/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import('@/views/regist.vue'),
  },
  
  {
    path: '/xieyi',
    name: 'xieyi',
    component: () => import('@/views/xieyi.vue'),
  }
]

const router = new VueRouter({
  routes
})

export default router
