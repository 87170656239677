import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  //这里放全局参数
	  daoqi:'',
	  pay:false,
  },
  getters: {
	  setDaoqiValue: state => state.daoqi,
	  setPay: state => state.pay
  },
  mutations: {
	  //这里是set方法
	  setDaoqiValue(state,daoqi){
		  console.log(111,daoqi)
	          state.daoqi = daoqi
	      },
	  setPay(state,pay){
	  		state.pay = pay
	      }
  },
  actions: {
  },
  modules: {
  }
})
